.bg-PlayerPage {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  min-height: 100vh;

  background-image: linear-gradient(to bottom, #ff33aa4d, #001866bd),
    url("../../../assets/fundoPlayer.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

.container {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;
}

.containerPagina {
  /*background-color: rgba(255, 255, 255, 0.5);*/
  display: grid;
  grid-template-rows: 1fr 1fr 0.4fr;
  width: 100%;
  height: 100vh;
}

.containerInformacoes {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
}

.logo {
  width: 250px;
  height: 250px;

  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.nomeCanal{
  padding: 0;
  margin: 0;
  color: white;
  font-size: 1.25rem;
}

.containerReprodutor {
  margin: 10px;
}

.containerPropagandas{
  display: grid;
  grid-template-rows: 1fr 0.1fr;
}

.patrocinios{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  
}

.patrocinios h6{
  color: white;
  font-size: 0.75rem;
}

.patrocinios p{
  color: white;
  font-size: 0.75rem;
  font-size: 15px;
}

.anuncios{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}


/*Tela de carregamento*/

.telaCarregamentPlayer{
  background-color: black;
  width: 100%;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.infoCarregamento{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.txtCarregando{
  color: white;
}



.botaoVoltar{
  position: absolute;
  color: white;

  border-radius: 50%;
  width: 35px;
  height: 35px;
  margin: 5px;

  display: flex;
  justify-content: center;
  align-items: center;


  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(25px);

  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  border-color: rgba(255, 255, 255, 0.1);

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

 

  cursor: pointer;
}

.botaoVoltar img{
  width: 25px;
  height: 25px;
}





/*Estilizacao Video*/

.containerVideo{
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* Proporção de aspecto para vídeos 16:9 */
}

.videoIFrame {
  
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.containerInfoVideo{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  
  
  padding: 5px 5px 5px 15px;
  color: white;

  margin-bottom: 50px;

  box-sizing: border-box;

  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(25px);

  border-style: solid;
  border-width: 1px;
  
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  border-color: rgba(255, 255, 255, 0.1);

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  

}

.containerInfoVideo h4{
  font-size: 1.25rem;
}



/* Media Queries */
@media (min-width: 768px) {
  .containerVideo{
    width: 50%;
    padding-bottom: calc(56.25%/2);
  }

  .containerInfoVideo{
    width: 50%;
  }
}