.botaoVoltar_cima{
    position: absolute;
    color: white;
  
    border-radius: 50%;
    width: 35px;
    height: 35px;
    margin: 5px;
  
    display: flex;
    justify-content: center;
    align-items: center;
  
  
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(25px);
  
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    border-color: rgba(255, 255, 255, 0.1);
  
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  
    cursor: pointer;
}

.botaoVoltar_baixo{
    position: absolute;
    bottom: 0;
    left: 0;
    color: white;
  
    border-radius: 50%;
    width: 35px;
    height: 35px;
    margin: 5px;
  
    display: flex;
    justify-content: center;
    align-items: center;
  
  
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(25px);
  
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    border-color: rgba(255, 255, 255, 0.1);
  
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  
    cursor: pointer;
}
  
  .btnIco{
    width: 25px;
    height: 25px;
  }