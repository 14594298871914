.infoOuvintes{
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    margin-top: 5px;
    color: white;
  }
  
  .iconOuvintes{
    width: 16px;
    height: 16px;
  }