.bg-ReprodutorDeAudio {
  background-color: rgba(255, 255, 255, 0.089);
  border-radius: 15px;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);

  display: grid;
  grid-template-columns: 1fr 15fr 1fr;

  padding: 20px;
  box-sizing: border-box;
}

.containerBotao {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.botaoPlayPause {
  width: 64px;
  height: 64px;
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 50%;

  margin: 0;
  padding: 0;
}

.botaoMutarDesmutar {
  width: 50px;
  height: 50px;
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 50%;

  margin: 0;
  padding: 0;
}

.containerNomeVolume {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;
  
}

.nomeVolume {
  color: white;
  margin: 0;
  padding: 0;
  width: 100%;
  justify-self: left;
}

.sliderVolume {
  width: 100%;
  margin: 0;
  padding: 0;
}
