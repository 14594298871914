.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  min-height: 100vh;
  

  background-image: linear-gradient(to bottom, #00000000, #000000),
    url("https://www.shutterstock.com/shutterstock/videos/1029188429/thumb/1.jpg?ip=x480");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.header {
  color: white;
}

.header-content {
  color: white;
  
}

.main-content {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.logoAca {
  font-size: 128px;
  font-weight: bold;
  font-family: "Polly";
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  color: white;
}

.description {
  font-size: 24px;
  margin-bottom: 40px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  color: white;
}

.sobre {
  display: flex;
  flex-direction: column;

  color: white;
  text-align: left;

  padding: 5px;
  margin-bottom: 1rem;
}

.btn-listen {
  display: inline-block;
  padding: 15px 30px;
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;
  color: #ffffff;
  background-image: linear-gradient(to right, #8531ff, #c218e8);
  border-radius: 50px;
  transition: background-image 5 ease-in;
}

.btn-listen:hover {
  background-image: linear-gradient(to right, #c218e8, #8531ff);
}

.footer {
  background-color: rgba(0, 0, 0, 0.8);
  padding: 20px;
  color: #ffffff;
  backdrop-filter: blur(8px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;

  text-align: center;

  box-sizing: border-box;
}

.footer-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-icon {
  width: 32px;
  height: 32px;
  margin-right: 10px;
}

.linkEmpresa {
  text-decoration: none;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
}

/* Media Queries */
@media (max-width: 768px) {
  .logoAca{
    font-size: 5.5rem;
  }
}

/* Footer Responsive */
@media (max-width: 576px) {
  .footer {
    flex-direction: column;
    text-align: center;
  }

  .logoAca{
    font-size: 75px;
  }

  .description{
    font-size: 15px;
  }
}

@font-face {
  font-family: "Polly";
  src: local("Polly Rounded Bold"), local("Polly-Rounded-Bold"),
    url("./../../../assets/fontes/PollyRounded-Bold.woff2") format("woff2"),
    url("./../../../assets/fontes/PollyRounded-Bold.woff") format("woff"),
    url("./../../../assets/fontes/PollyRounded-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
