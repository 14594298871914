.bgCanaisPage {
  display: flex;
  flex-direction: column;
  align-items: center;

  min-height: 100vh;

  background-image: linear-gradient(to bottom, #1800444d, #00054ebd),
    url("https://www.shutterstock.com/shutterstock/videos/1049196475/thumb/1.jpg?ip=x480");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  background-image: linear-gradient(to bottom, #000000, #00000000);

  margin-bottom: 20px;
  padding: 5px;
}

.header h1 {
  text-align: center;
  color: white;
}

.header p {
  text-align: center;
  color: white;
}

.barraDeBuscaContainer {
  background-color: rgba(255, 0, 0, 0);
  padding: 0.5rem;
  height: 50px;
  width: 100%;
  max-width: 750px;

  display: flex;
  flex-direction: row;

  position: sticky;
  top: 0;
  z-index: 1;
}

.barraDeBusca {
  background-color: rgba(0, 0, 255, 0);
  height: 100%;
  
  /* se for usar o componente filtro, usa essas
  border-top-left-radius: 5rem;
  border-bottom-left-radius: 5rem;
 */
  
  border-radius: 5rem;

  width: 100%;

  border-style: solid;
  border-width: 1px 0px 1px 1px;
  
  border-color: rgba(255, 255, 255, 0.1);

  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 0.5rem 0.5rem 0.5rem 1rem;

  backdrop-filter: blur(32px);

  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.iconeBarraDeBusca {
  height: 100%;
  margin-right: 10px;
}

.inputFiltro{
    background-color: #00000000;
    border: none;
    color: white;
    width: 100%;
}
.inputFiltro:focus{
    outline: none;
}
.inputFiltro::placeholder{
    color: rgba(255, 255, 255, 0.75);
}

.listaCanaisContainer{
    background-color: rgba(255, 255, 255, 0);
    width: 100%;
    max-width: 550px;
}