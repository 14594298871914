.canal {
  width: 100%;
  max-width: 750px;
  height: 125px;

  margin-bottom: 10px;
  padding: 10px;

  display: flex;
  flex-direction: row;

  box-sizing: border-box;

  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(25px);

  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  border-color: rgba(255, 255, 255, 0.1);

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  transition: transform 0.1s ease-in-out;

  cursor: pointer;
}

.canal:hover {
  transform: scale(1.0075);
}

.logoCanal {
  height: 100%;
  margin-right: 10px;
  border-radius: 5px;
}

.informacoesCanal {
  display: grid;
  grid-template-rows: 1fr 1fr;
}

.informacoesCanal p {
  color: white;

  font-size: 12px;
}

.informacoesPrimarias {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.nomeCanal {
  font-size: 17px !important;
}

.informacoesSecundarias {
  display: flex;
  flex-direction: column;

  justify-content: flex-end;
}

/*Gambiarra começa aqui*/
.statusCanal {
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: space-between;
  flex: 1;
}

.statusCanal img {
  width: 32px;
  height: 32px;

  
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 50%;
}

.statusStreamNoAr {
  color: rgb(0, 255, 0);
}

.statusStreamForaDoAr {
  color: rgb(255, 0, 0);
}
