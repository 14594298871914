/*.appContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}*/

*{
  margin: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  
  background-color: rgb(255, 255, 255);
}
