.filtro{
    height: 100%;
    border-top-right-radius: 5rem;
    border-bottom-right-radius: 5rem;
    border: none;

    width: 25%;

    color: white;
    background-image: linear-gradient(to bottom, #686868, #242424);

    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.filtro:focus{
    outline: none;
}