.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  backdrop-filter: blur(8px);
}

.modalContent {
  background-color: white;
  padding: 20px;
  border-radius: 4px;
  margin: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-color: rgba(255, 255, 255, 0.1);

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.modalContent h2 {
  margin-top: 0;
  margin-bottom: 15px;
  text-align: center;
}

.modalContent p {
  margin-bottom: 10px;
}

.modalContent button {
  margin-top: 10px;
  border-style: none;
  color: #ffffff;
  background: linear-gradient(to right, #8531ff, #c218e8);
  border-radius: 50px;
  padding: 10px;

  transition: background-image 5 ease-in;
}

.modalContent button:hover {
  background: linear-gradient(to right, #c218e8, #8531ff);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
