.listaCanais {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;

  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  
  box-sizing: border-box;
  
}

@media (max-width: 768px) {
  .lista-canais {
   
    
    margin-top: 40vh;
  }
}

